import React from 'react'
import { Link } from "gatsby"
import { rhythm } from './../utils/typography'

const Project = ({ title, date, description, image, link }) => (
    <article>
        <header>
            <h3
                style={{
                    marginBottom: rhythm(1 / 4),
                }}
            >
                {title}
            </h3>
            <small>{date}</small>
        </header>
        <section>
            <p>{description}</p>
            {!!image ? <img src={image} /> : null}
            {!!link ? (<Link to={link.url}>{link.title}</Link>) : null}
        </section>
    </article>
)

export default Project
