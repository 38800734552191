import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Project from "../components/project"

import { rhythm } from "../utils/typography"

const ProjectsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Projects" />
      <Project
        title="The Rabbithole"
        date="March 2020 - current"
        link={{ title: 'Photo Album', url: 'google.com' }}
        image="https://nonprofitquarterly.org/wp-content/uploads/2019/08/rabbit-hOle-logo.jpg"
        description="I started a coliving house with @__drewface and @choosymom. We moved in just before covid hit, so we had a rough start. Some housemates left to quarantine at home or because they had lost their job. Some roommates disagreed about how strict we should be about covid protocols. But over time we created a wonderful home. On any given day you can find us eating food that Eric grilled, rock climbing together at Mission Cliffs, or throwing socially distant dance parties."
      />
      <Project
        title="AirGarage"
        date="September 2019 - current"
        link={{ title: 'Career Crashers Podcast: AirGarage', url: 'google.com' }}
        image="https://nonprofitquarterly.org/wp-content/uploads/2019/08/rabbit-hOle-logo.jpg"
        description="I joined AirGarage as their first engineering hire. ???"
      />
      <Project
        title="ScooterScout"
        date="??"
        link={{ title: 'ScooterScout', url: 'google.com' }} // might pay to host it again?
        image="https://nonprofitquarterly.org/wp-content/uploads/2019/08/rabbit-hOle-logo.jpg"
        description="In 2018 ?? I moved to San Francisco and fell in love with city life. I needed a way to get around the city since driving and parking here is a huge headache, so I bought myself an electric scooter. ???"
      />
    </Layout>
  )
}

export default ProjectsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
